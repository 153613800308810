.aboutStudioContainer {
  z-index:5000 ;
  position: relative;
    margin-top: 20px;
    padding: 2.5rem;
  }
  
  .about-studio-image {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .about-studio-tabs .nav-link {
    color: #09acba; 
    border: 1px solid #dee2e6;
    border-radius: 5px;
    font-weight: bolder;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  a {
    color: white!important;
  }
  .about-studio-tabs .nav-link.active {
    color: white !important; 
    background-color: #09acba !important; 
  }
  
  

  .aboutStudioContent {
    padding: 20px;
    color: white;

    margin-top: 20px;
  }
  
  a {
    color: white ;
    text-decoration: none;
}
.aboutStudioTabs .nav-link.active {
  color: #09acba !important; 
}

.aboutStudioTabs .nav-link:hover {
  color: #00ffff !important; 
}

