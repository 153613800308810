body {
    width: 100%;
    display: block;
    overflow-x: hidden;
    background-color: black !important;
    color: #c4c3ca;
}


a:hover {
    text-decoration: none;
}

.shadow-title {
    position: fixed;
    top: 0;
    margin-top: 30px;
    left: 20px;
    width: 100%;
    height: 300%;
    text-align: left;
    font-family: 'Muli', sans-serif;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    font-size: 18vw;
    line-height: 1;
    color: rgba(200, 200, 200, .1);
    background: linear-gradient(90deg, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.35));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
}

.section-parallax {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.section {
    position: relative;
    width: 100%;
    display: block;
}

.full-height {
    height: 100vh;
}

.half-height {
    height: 50vh;
    text-align: center;
}

.section-title-wrap {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

.section-title-wrap p {
    letter-spacing: 2px;
    font-size: 22px;
    font-weight: 700;
    padding: 0;
    color: #08abb0;
}

.section-title-wrap h1 {
    margin: 0;
    padding: 0;
    font-size: 5vw;
    letter-spacing: 8px;
    font-weight: 900;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
}

.case-study-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}

.case-study-images li {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    list-style: none;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.case-study-images li .img-hero-background {
    position: absolute;
    width: calc(100% - 80px);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    overflow: hidden;
    list-style: none;
    opacity: 1;
    border-radius: 20px;
    box-shadow: 0 0 50px rgba(0, 0, 0, .6);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}
.img-hero-background img{
    width: 100%;
}

.case-study-images li .img-hero-background video {
    width: 100%;
    height: auto;
    display: block;
}

.case-study-images li.show .img-hero-background {
    opacity: 1;
}

.case-study-images li .case-study-title {
    position: absolute;
    top: 0;
    height: 100%;
    text-align: center;
    left: 30px;
    display: block;
    opacity: 0;
    letter-spacing: 1px;
    font-size: 14px;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    font-style: italic;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.case-study-images li.show .case-study-title {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.case-study-images li .hero-number-back {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    list-style: none;
    opacity: 0;
    font-size: 100px;
    font-weight: 900;
    line-height: 1;
    color: rgba(255, 255, 255, .2);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.case-study-images li .hero-number {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 40px;
    margin-left: -70px;
    display: block;
    letter-spacing: 2px;
    text-align: center;
    list-style: none;
    opacity: 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.case-study-images li .hero-number-fixed {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: 30px;
    width: 40px;
    text-align: center;
    display: block;
    letter-spacing: 2px;
    list-style: none;
    font-size: 13px;
    font-weight: 300;
    line-height: 1;
    color: #fff;
}

.case-study-images li .hero-number-fixed:before {
    position: absolute;
    content: '';
    top: 50%;
    left: -60px;
    width: 60px;
    height: 1px;
    background-color: rgba(255, 255, 255, .4);
}

.case-study-images li.show .hero-number {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.case-study-images li.show .hero-number-back {
    opacity: 1;
}

/* #back to top arrow
================================================== */

.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    visibility: hidden;
    width: 10px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 10px 40px;
    cursor: pointer;
    background-image: url('http://www.ivang-design.com/svg-load/parallax/arrow-up.png');
    transform: translateY(-15px);
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;
}

.scroll-to-top:hover {
    bottom: 32px;
}

.scroll-to-top.active-arrow {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* #Media
================================================== */

@media (max-width: 1200px) {
    .scroll-to-top {
        right: 13px;
    }

    .case-study-images li .case-study-title {
        left: 13px;
    }
    .half-height {
        height: 40vh;
        text-align: center;
    }
    .full-height {
        height: 90vh;
    }
}

@media (max-width: 991px) {
    .case-study-images li .hero-number-back {
        font-size: 26vw;
    }
    .half-height {
        height: 35vh;
        text-align: center;
    }
    .full-height {
        height: 50vh;
    }
}

@media (max-width: 767px) {
    .case-study-wrapper {
        background-color: rgba(31, 32, 41, .95);
        box-shadow: 0 0 10px rgba(0, 0, 0, .8);
    }

    .case-study-wrapper .case-study-name a {
        font-size: 11px;
        letter-spacing: 1px;
        text-align: center;
        margin: 5px;
    }

    .case-study-wrapper .case-study-name {
        margin: 0 auto;
        text-align: center;
    }

    .case-study-images li .hero-number-back {
        font-size: 32vw;
    }
    .half-height {
        height: 25vh;
        text-align: center;
    }
    .full-height {
        height: 50vh;
    }
    
}

/* #Link to page
================================================== */

.link-to-portfolio {
    position: fixed;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 3px;
    background-position: center center;
    background-size: cover;
    background-image: url('https://ivang-design.com/ig-logo.jpg');
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
    transition: opacity .2s, border-radius .2s, box-shadow .2s;
    transition-timing-function: ease-out;
}

.link-to-portfolio:hover {
    opacity: 0.8;
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgba(255, 255, 255, .1);
}