.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4%;
    padding: 2% 0;
}

.card {
    padding: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 25px;
    text-align: center; /* Center everything inside the card */
}

.card_title {
    font-weight: normal;
    font-size: 36px;
    margin-bottom: 20px;
}
.time{
    padding-top: 0px;
    text-align: center;
    font-weight: normal;
    font-size: 15px;  
}
.pricing {
    text-align: center;
    font-weight: normal;
    font-size: 50px;
    padding-top: 10px;
}

/* Center the hr */
.card hr {
    width: 50%;
    margin: 20px auto; /* Ensure hr is centered and has spacing */
}

.pricing .small {
    font-size: 16px;
}

hr {
    margin-top: 70px;
}

a.cta_btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    background: #09ACB1;
    border-radius: 29px;
    padding: 20px 0;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
    transition: background .3s ease;
}

a.cta_btn:hover {
    background: #000;
}


.link i {
    padding-left: 7px;
}

.link:hover {
    text-decoration: underline;
    background-color: black;
}

@media only screen and (max-width: 1200px) {
    .grid {
        grid-template-columns: 1fr 1fr;
        gap: 4%;

    }
}

@media only screen and (max-width: 780px) {
    .grid {
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 30px;
    }

    .container {
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-content: center;
    }


    .card {
        padding: 30px;
        max-width: 400px;
    }

    .card_title {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .pricing {
        font-size: 52px;
    }

    hr {
        margin-top: 50px;
    }

    a.cta_btn {
        font-size: 11px;
    }
}