/* Default styles */
.wrapper-left {
    color: white;
    display: flex;

}

.wrapper-left h1 {
    margin: 0;
}

.wrapper-left .head {
    letter-spacing: -2px;
    font-size: 35px;
    font-weight: bold;
}

.wrapper-left .head:after {
    content: "";
    width: 100%;
    border-bottom: 0.5rem solid #09acba;
    display: block;
    margin-bottom: 25px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .wrapper-left .head {
        font-size: 30px; /* Adjust font size for smaller screens */
    }
}

/* Media query for even smaller screens */
@media screen and (max-width: 576px) {
    .wrapper-left .head {
        font-size: 25px;
    }
}
