.about-section {
  position: relative;
  padding: var(--s2) var(--s3) var(--s4);
}

.about-section .content-column {
  position: relative;
  margin-bottom: 40px;
}


.about-section .content-column .text {
  position: relative;
  color: white;
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 40px;
}

.about-section .content-column .email {
  position: relative;
  color: white;
  font-weight: 700;
  margin-bottom: 50px;
}

.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .image-column .inner-column {
  position: relative;
  padding: 40px 40px 0px 0px;
  margin-left: 50px;
}

.about-section .image-column .inner-column:after {
  position: absolute;
  content: '';
  right: 0px;
  top: 0px;
  left: 40px;
  bottom: 100px;
  z-index: -1;
  border: 2px solid #09acba;
}

.about-section .image-column .inner-column .image {
  position: relative;
}



.about-section .image-column .inner-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.about-section .image-column .inner-column .image .overlay-box {
  position: absolute;
  left: 40px;
  bottom: 48px;
}

.about-section .image-column .inner-column .image .overlay-box .year-box {
  position: relative;
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
  padding-left: 125px;
}

.about-section .image-column .inner-column .image .overlay-box .year-box .number {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 110px;
  height: 110px;
  color: #09acba;
  font-size: 68px;
  font-weight: 700;
  line-height: 105px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.about-section .btn-style-three:before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  z-index: -1;
  right: -10px;
  bottom: -10px;
}

.about-section .btn-style-three:hover {
  color: #ffffff;
  background: #09acba;
}

.about-section .btn-style-three {
  position: relative;
  line-height: 24px;
  color: #09acba;
  font-size: 15px;
  font-weight: 700;
  background: none;
  display: inline-block;
  padding: 11px 40px;
  background-color: #ffffff;
  text-transform: capitalize;
  border: 2px solid #09acba;
}

.sec-title2 {
  color: #fff;
}

.sec-title {
  position: relative;
  padding-bottom: 40px;
}

.sec-title .title {
  position: relative;
  color: #09acba;
  font-weight: 700;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: capitalize;
}