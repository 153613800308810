.container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px; /* Approximately 2rem */
    padding: 1rem; /* Approximately 2.5rem */
}

.container1 {
    background: linear-gradient(to bottom, black, #09acba);
}

.form {
    width: 100%;
    max-width: 1000px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.form .text {
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.contact-form {
    background-color: #09acba;
    position: relative;
}

.circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #09acba);
    position: absolute;
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}

.contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #09acba;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

form {
    padding: 37px 35px; /* Approximately 2.3rem 2.2rem */
    z-index: 10;
    overflow: hidden;
    position: relative;
}

.title {
    color: #fff;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 12px; /* Approximately 0.7rem */
}

.input-container {
    position: relative;
    margin: 32px 0; /* Approximately 2rem */
}

.input {
    width: 70%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 10px 10px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 16px;
}

.input::placeholder {
    color: #fff;
}

textarea.input {
    padding: 10px;
    min-height: 150px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
}

.btn {
    padding: 10px;
    background-color: #fff;
    border: 2px solid #fafafa;
    color: #09acba;
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 70%;
    font-size: 20px;
}

.btn:hover {
    background-color: transparent;
    color: #fff;
}

.contact-info {
    padding: 37px 35px; /* Approximately 2.3rem 2.2rem */
    position: relative;
}

.contact-info .title {
    color: #09acba;
}

.text {
    color: #333;
    font-size: 22px; /* Adjust the size to your preference */
    padding: 0; /* Remove the margin */
    line-height: 1.5;

    
    
}
.information p{
    margin: 0;
    font-size: 18px;
}


.information {
    display: flex;
    color: #555;
    margin: 11px 0; /* Approximately 0.7rem */
    align-items: center;
}

.information i {
    color: #09acba;
}

.icon {
    width: 28px;
    margin-right: 11px; /* Approximately 0.7rem */
}

.social-media {
    padding: 20px 0 0 0; /* Approximately 2rem */
}

.social-media p {
    color: #333;
    padding-top: 150px;
    padding-left: 0;
    padding-bottom: 0;
    
}

.social-icons {
    display: flex;
    margin-top: 8px; /* Approximately 0.5rem */
}

.social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: #09acba;
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 8px; /* Approximately 0.5rem */
    transition: 0.3s;
}

.social-icons a:hover {
    transform: scale(1.05);
}

.contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #09acba;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
}

.big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: #09acba;
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
}

.big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
}

.square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
}

@media (max-width: 850px) {
    .form {
        grid-template-columns: 1fr;
    }

    .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }

    .contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
    }

    .square {
        transform: translate(140%, 43%);
        height: 350px;
    }

    .big-circle {
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%;
    }

    .text {
        margin: 16px 0 24px 0; /* Approximately 1rem 1.5rem */
    }

    .social-media {
        padding: 24px 0 0 0; /* Approximately 1.5rem */
    }
}

@media (max-width: 480px) {
    .container {
        padding: 24px; /* Approximately 1.5rem */
        align-items: center;

    }

    .contact-info:before {
        display: none;
    }

    .square,
    .big-circle {
        display: none;
    }
    .contact-form{
        width: 80%;


    }
    form,
    .contact-info {
        padding: 27px 26px; /* Approximately 1.7rem 1.6rem */
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .icon {
        width: 23px;
    }

    .input {
        padding: 7px 19px; /* Approximately 0.45rem 1.2rem */
    }

    .btn {
        padding: 7px 19px; /* Approximately 0.45rem 1.2rem */
    }
}
