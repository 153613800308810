.linkstyle {
  text-decoration: none;
}
.containerpagesignin {
  display: flex;
  justify-content: center;
  align-content: center;
    height: 100vh;
}
.containerform {
    display: flex;
    justify-content: center;
    align-items: center;
}
.minicontainerform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    
}
form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

}
.form {
    width: 400px;
}

form label {
    display: flex;
    justify-content: center;
    align-items: center;
}

form label input {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    border: 1px solid black;
    color: black;
    border-radius: 15px;
    width: 100%;
    height: 35px;

  }
  form label input::placeholder {
    color: #7d7e80;
    font-size: 1rem;
    font-weight: 100;

  }
  .containerforget {
    display: flex;
    justify-content: left;
    padding: 0 0 0 5px;
  }
  .containerinputforget {
    display: flex;
    flex-direction: column;
  }
 
  .containerforget span {
    cursor: pointer;
    font-size: 1em;
  }

  .containersigninimg {
    display: flex;
    justify-content: center;
    align-items: cente8;
  }
  .containersigninimg img {
    width: 70%;
  }
  #btnsignup {
    all: unset;
    width: 100%;
    height: 40px;
    background-color: #007DFE;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
  #btnsignup span {
    font-size: 1.5rem;
    font-weight:500;
    cursor: pointer;
    color: white;
  }
  .cotainercontinuewith {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .cotainercontinuewith span {
    font-size: 1rem;
    font-weight: 100;
    color: #ffffff;
  }
  .lineconinue {
    width: 29%;
    background-color: rgb(255, 255, 255);
    height: 1px;
  } 
  .containergoog {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    height: 35px;
    border-radius: 15px;
    cursor: pointer;
  }
  .containerinfosupp {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .containergoog img {
    width: 5%;
    padding-right: 10px;
  }
  .containergoog span {
    font-size: 1.4rem;
  }
  .containerbtnsignup {
    text-align: center;
  }
  .containerbtnsignup  p{
    height: 2px;
  }
  #altersignin {
    color: #132B61;
    cursor: pointer;
    
  }

  /*   Responsive   */


  @media (max-width :600px) {
    .containersigninimg {
        display: none;
    }
    .containerpagesignin {
        grid-template-columns: 1fr;
    }
    .minicontainerform {
        box-shadow: 8px 8px 8px 8px rgba(34, 32, 32, 0.1);
        padding: 20px;
    }
    .minicontainerform h1 {
        font-size: 1.8rem;
    }
    .containergoog img {
        width: 8%;
      }
      .cotainercontinuewith span {
        font-size: 1rem;
      }
      .lineconinue {
        width: 25%;
      } 
  }


 
  

  

 