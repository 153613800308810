

.demo-app {
  display: flex;
  height: 100%;
  font-size: 14px;
}


.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.fc a * {
  color: white !important;
  text-decoration: none !important;
}
