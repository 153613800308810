.navbar_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    position: fixed;
    z-index: 1000000000000;
    right: 50px;
}

.navbar_elt {
    margin-top: 20px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 1);
    height: 40px;
    width: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    list-style: none;
}

.navbar_elt:hover {
    background-color: #09acba;
    border: #09acba;
    cursor: pointer;
}

.navbar_elt:hover {
    color: white;
    transition: 0.5s;
}
.fas {
  color: #09acba;
}
.navbar_elt:hover .fas {
  color: white; /* Color when hovered */
}

.navbar_elt {
    color: #09acba;
}
/* Media Queries for mobile devices */
@media (max-width: 768px) {
        .navbar_container {
          width: 100%;
          position: fixed;
          right: 0;
          justify-content: center;
          flex-direction: row;
          top:90%;
          height: 10%;
        background-color: black;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow for the navbar */
        }
        .navbar {
            display: flex;
           gap: 20px;
           width: 100%;
           justify-content: center;
           align-content: center;
           flex-wrap: wrap;
            list-style: none;
          }
          
          .navbar_elt {
            margin-top: 0px;
         
        }
  
  }