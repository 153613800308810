a {
    text-decoration: none;
}

footer {
    color: #333;
    background-color: #fff;
    position: relative;
    bottom: 0;
    width: 100%;
}

.row {
    display: flex;
    padding: 1rem;
    width: 100%;
}

.column2 {
    width: calc(100% / 3);
    padding: 0 0.9375rem;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column {
    width: calc(100% / 3);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: solid;
    border-width: 0.2rem;
    border-color: #333;
}

h4 {
    margin-bottom: 0.9375rem;
    font-size: 2.5rem;
}

h5 {
    margin-bottom: 0.9375rem;
    padding-top: 0rem;
    font-size: 2.7rem;
}



p {
    font-size: 20px;
    line-height: 2rem;
    text-align: center;
    padding: 15px;
}

.column1 {
    width: calc(100% / 3);
    margin-bottom: 0rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border-left: solid;
    border-color: #333;
    border-width: 0.2rem;
}

.Logo {
    margin-top: 2rem;
    margin-left: 22%;
    padding-bottom: 2rem;
    height: 80%;
    width: 80%;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120%;
    gap: 0.5rem;
}

.social-icons a .fab {
    width: 35px;
    height: 35px;
    padding-top: 0.5rem;
    border-radius: 0.8rem;
    text-align: center;
    margin-right: 0.3rem;
}
.fab{
    color: #09acba;
}

.social-icons a:hover {
    transform: scale(1.05);
}


/* Responsive adjustments for mobile version */
@media screen and (max-width: 768px) {
    .column {
        margin: 0.4rem 0;
        width: 100%;
        padding: 0;
        border-right: none;
        border-bottom: solid;
        border-width: 0.1rem;
        border-color: #333;
        padding-bottom: 3rem;

    }

    .column2 {
        margin: 0.4rem 0;
        width: 100%;
        padding-bottom: 2rem;
    }

    .column1 {
        margin: 0.4rem 0;
        width: 100%;
        padding: 0;
        border-left: none;
        border-top: solid;
        border-width: 0.1rem;
        border-color: #333;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    h4 {
        font-size: 3.3125rem;
    }

    p {
        font-size: 1.9375rem;
    }

    ul li a {
        font-size: 0.9375rem;
    }

    ul.social-icons li a {
        width: 1.975rem;
        height: 1.975rem;
    }
}